<template>
  <el-dialog title="查询结果" width="500" :visible.sync="visible" :fullscreen="full" :close-on-click-modal="false" :close-on-press-escape="false" custom-class="v-dialog v-dialog--highLight risk-dialog" @open="computeHeight">
    <div>
      <tool :full-screen.sync="full" @download="download('手机实名认证查询结果')"></tool>
      <h2 class="risk-dialogTitle">手机实名认证查询结果</h2>
      <table class="risk-dialogTable">
        <colgroup>
          <col width="150"/>
          <col width="250"/>
        </colgroup>
        <tbody>
          <tr>
            <td>姓名</td>
            <td>{{v.result.data.Name}}</td>
          </tr>
          <tr>
            <td>手机号码</td>
            <td>{{v.result.data.Mobile}}</td>
          </tr>
          <tr>
            <td>身份证号</td>
            <td>{{v.result.data.id}}</td>
          </tr>
          <tr>
            <td>实名认证是否通过</td>
            <td>{{v.result.resp.desc}}</td>
          </tr>
          <tr>
            <td>查询时间</td>
            <td>{{miment().format('YYYY年MM月DD日 hh点mm分')}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </el-dialog>
</template>

<script>
import mixin from './mixin'
export default {
  data() {
    return {
      visible: false,
      v: null
    }
  },
  methods: {
    open(datas) {
      this.visible = true
      this.v = Object.assign(this.v, datas)
    }
  },
  mixins: [mixin],
  created() {
    // 初始化数据
    this.v = JSON.parse("{\"code\":\"\",\"charge\":\"\",\"msg\":\"\",\"result\":{\"data\":{\"Mobile\":\"\",\"Name\":\"\",\"id\":\"\"},\"resp\":{\"code\":\"\",\"desc\":\"\"}}}");
  }
}
</script>

